import React from 'react';
import { Navbar } from 'react-bootstrap';
import siteBrandDark from '~image/logo/logo-black.png';
import siteBrandLight from '~image/logo/logo-white.png';
import Menu from './Menu';
import Logo from '../logos/actovos';

const SiteNavbar = ({ buttonBlock, darkLogo, customLogo, defaultLogo }) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="site-navbar"
      >
        <Navbar.Brand href="/">
          <Logo width={200} color="#136de8" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="hamburgur"
        />
        <Menu />
        {buttonBlock}
      </Navbar>
    </>
  );
};

export default SiteNavbar;
