import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '~image/favicon.ico';
import Header from '../Header';
export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <title>Actovos - Connect with your audience</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <Header />
      {children}
    </>
  );
}
