import PropTypes from 'prop-types';
import React from 'react';
import SVG from './svg';

const Logo = ({ color, width }) => (
  <div style={{ width }}>
    <SVG viewBox="0 0 292.28 48.14" color={color}>
      <path
        d="M90.45,39.76H83.3l-3.11-6.73H63.88l-3.15,6.73h-7.15L69.47,6.12h5.09L90.45,39.76L90.45,39.76z M77.63,27.61
	L72.03,15.5l-5.68,12.11H77.63L77.63,27.61L77.63,27.61z M124.59,33.7c-1.68,2.05-3.72,3.67-6.12,4.88
	c-2.4,1.21-5.04,1.81-7.93,1.81c-2.49,0-4.84-0.46-7.02-1.37c-2.19-0.91-4.09-2.15-5.72-3.72c-1.63-1.57-2.91-3.41-3.85-5.53
	c-0.94-2.12-1.41-4.39-1.41-6.83c0-2.44,0.47-4.72,1.41-6.83c0.94-2.12,2.22-3.97,3.85-5.55c1.63-1.58,3.53-2.82,5.72-3.72
	c2.19-0.9,4.53-1.35,7.02-1.35c2.89,0,5.53,0.6,7.93,1.81c2.4,1.21,4.39,2.78,5.99,4.71l-4.5,4.63c-1.04-1.49-2.38-2.68-4.02-3.6
	c-1.64-0.91-3.44-1.37-5.4-1.37c-1.57,0-3.05,0.3-4.44,0.9c-1.39,0.6-2.6,1.42-3.64,2.44s-1.86,2.21-2.46,3.57
	c-0.6,1.36-0.9,2.81-0.9,4.35c0,1.51,0.3,2.95,0.9,4.31c0.6,1.36,1.42,2.55,2.46,3.57c1.04,1.02,2.25,1.84,3.64,2.44
	c1.39,0.6,2.87,0.9,4.44,0.9c2.07,0,3.91-0.47,5.51-1.41c1.6-0.94,2.93-2.18,3.99-3.72L124.59,33.7L124.59,33.7z M151.04,11.96
	h-9.29v27.79h-6.6V11.96h-9.29v-5.8h25.18V11.96L151.04,11.96z M189.67,22.94c0,2.41-0.47,4.68-1.41,6.81
	c-0.94,2.13-2.22,3.98-3.85,5.55s-3.52,2.81-5.68,3.72c-2.16,0.91-4.46,1.37-6.9,1.37c-2.44,0-4.74-0.46-6.92-1.37
	c-2.17-0.91-4.07-2.15-5.7-3.72c-1.63-1.57-2.91-3.41-3.85-5.53c-0.94-2.12-1.41-4.39-1.41-6.83c0-2.44,0.47-4.72,1.41-6.85
	c0.94-2.13,2.22-3.98,3.85-5.55c1.63-1.57,3.52-2.8,5.7-3.7c2.17-0.9,4.48-1.35,6.92-1.35c2.44,0,4.74,0.45,6.9,1.35
	c2.16,0.9,4.05,2.14,5.68,3.72s2.91,3.43,3.85,5.55C189.2,18.22,189.67,20.5,189.67,22.94L189.67,22.94L189.67,22.94z M183.11,22.94
	c0-1.54-0.29-2.99-0.88-4.35c-0.59-1.36-1.39-2.55-2.42-3.57c-1.02-1.02-2.22-1.84-3.59-2.44c-1.37-0.6-2.83-0.9-4.37-0.9
	c-1.57,0-3.04,0.3-4.41,0.9c-1.37,0.6-2.57,1.42-3.59,2.44c-1.02,1.02-1.83,2.21-2.42,3.57s-0.88,2.81-0.88,4.35
	c0,1.51,0.29,2.96,0.88,4.33c0.59,1.37,1.39,2.57,2.42,3.6c1.02,1.02,2.22,1.84,3.59,2.44c1.37,0.6,2.84,0.9,4.41,0.9
	c1.54,0,3-0.3,4.37-0.9c1.37-0.6,2.57-1.42,3.59-2.44c1.02-1.02,1.83-2.22,2.42-3.6C182.82,25.9,183.11,24.45,183.11,22.94
	L183.11,22.94L183.11,22.94z M223.73,6.12l-12.74,33.64h-5.97L191.99,6.12h7.23l8.75,23.63l8.62-23.63H223.73L223.73,6.12
	L223.73,6.12z M261.74,22.94c0,2.41-0.47,4.68-1.41,6.81c-0.94,2.13-2.22,3.98-3.85,5.55c-1.63,1.57-3.52,2.81-5.68,3.72
	c-2.16,0.91-4.46,1.37-6.9,1.37c-2.44,0-4.74-0.46-6.92-1.37c-2.17-0.91-4.07-2.15-5.7-3.72c-1.63-1.57-2.91-3.41-3.85-5.53
	c-0.94-2.12-1.41-4.39-1.41-6.83s0.47-4.72,1.41-6.85c0.94-2.13,2.22-3.98,3.85-5.55c1.63-1.57,3.52-2.8,5.7-3.7
	c2.17-0.9,4.48-1.35,6.92-1.35c2.44,0,4.74,0.45,6.9,1.35c2.16,0.9,4.05,2.14,5.68,3.72c1.63,1.58,2.91,3.43,3.85,5.55
	C261.27,18.22,261.74,20.5,261.74,22.94L261.74,22.94L261.74,22.94z M255.18,22.94c0-1.54-0.29-2.99-0.88-4.35
	c-0.59-1.36-1.39-2.55-2.42-3.57c-1.02-1.02-2.22-1.84-3.59-2.44c-1.37-0.6-2.83-0.9-4.37-0.9c-1.57,0-3.04,0.3-4.41,0.9
	c-1.37,0.6-2.57,1.42-3.59,2.44c-1.02,1.02-1.83,2.21-2.42,3.57c-0.59,1.36-0.88,2.81-0.88,4.35c0,1.51,0.29,2.96,0.88,4.33
	c0.59,1.37,1.39,2.57,2.42,3.6c1.02,1.02,2.22,1.84,3.59,2.44s2.84,0.9,4.41,0.9c1.54,0,3-0.3,4.37-0.9
	c1.37-0.6,2.57-1.42,3.59-2.44c1.02-1.02,1.83-2.22,2.42-3.6C254.88,25.9,255.18,24.45,255.18,22.94L255.18,22.94L255.18,22.94z
	 M289.23,30.93c0,1.26-0.27,2.46-0.82,3.6c-0.55,1.14-1.31,2.14-2.29,3.01c-0.98,0.87-2.14,1.56-3.49,2.08s-2.83,0.78-4.46,0.78
	c-1.46,0-2.77-0.08-3.93-0.25c-1.16-0.17-2.26-0.43-3.28-0.8c-1.02-0.36-2-0.83-2.94-1.41c-0.94-0.57-1.91-1.27-2.92-2.08l4.62-4.67
	c1.35,1.12,2.66,1.91,3.95,2.38c1.29,0.46,2.69,0.69,4.2,0.69c0.62,0,1.2-0.09,1.74-0.27c0.55-0.18,1.02-0.43,1.43-0.74
	c0.41-0.31,0.73-0.67,0.97-1.09c0.24-0.42,0.36-0.88,0.36-1.39c0-0.53-0.1-1.01-0.29-1.43c-0.2-0.42-0.55-0.83-1.05-1.22
	c-0.5-0.39-1.21-0.79-2.1-1.2c-0.9-0.41-2.05-0.86-3.45-1.37c-1.23-0.45-2.42-0.96-3.55-1.53c-1.14-0.57-2.13-1.27-2.99-2.08
	c-0.85-0.81-1.54-1.77-2.06-2.86s-0.78-2.38-0.78-3.87c0-1.43,0.29-2.75,0.86-3.95c0.57-1.21,1.36-2.24,2.35-3.09
	c1-0.85,2.16-1.52,3.49-2c1.33-0.48,2.77-0.71,4.31-0.71c2.58,0,4.75,0.34,6.52,1.01c1.77,0.67,3.34,1.65,4.71,2.94l-4.54,4.58
	c-0.84-0.81-1.78-1.44-2.82-1.87c-1.04-0.43-2.26-0.65-3.66-0.65c-0.64,0-1.24,0.1-1.79,0.29c-0.55,0.2-1.02,0.46-1.41,0.78
	c-0.39,0.32-0.71,0.7-0.95,1.14c-0.24,0.43-0.36,0.89-0.36,1.37c0,0.53,0.11,1.02,0.34,1.45c0.22,0.43,0.6,0.85,1.14,1.26
	c0.53,0.41,1.24,0.81,2.12,1.22c0.88,0.41,1.98,0.85,3.3,1.32c1.68,0.59,3.13,1.23,4.33,1.93c1.21,0.7,2.19,1.47,2.94,2.31
	c0.76,0.84,1.32,1.79,1.68,2.84C289.05,28.43,289.23,29.61,289.23,30.93L289.23,30.93L289.23,30.93z"
      />
      <path
        d="M24.99,2.34c-3.35,0-6.13,2.54-6.53,5.79C11.13,10.8,5.88,17.82,5.88,26.06c0,1.06,0.08,2.1,0.25,3.11
	c-1.75,1.19-2.88,3.2-2.88,5.46c0,3.62,2.97,6.59,6.59,6.59c0.99,0,1.93-0.22,2.78-0.62c3.33,2.84,7.66,4.57,12.38,4.57
	s9.04-1.73,12.38-4.57c0.85,0.4,1.79,0.62,2.78,0.62c3.62,0,6.59-2.97,6.59-6.59c0-2.26-1.14-4.27-2.88-5.46
	c0.17-1.01,0.25-2.05,0.25-3.11c0-8.25-5.24-15.27-12.58-17.94C31.13,4.87,28.35,2.34,24.99,2.34L24.99,2.34z M24.99,4.97
	c2.2,0,3.95,1.75,3.95,3.95c0,2.2-1.75,3.95-3.95,3.95s-3.95-1.75-3.95-3.95C21.04,6.73,22.8,4.97,24.99,4.97L24.99,4.97z
	 M18.67,10.82c0.82,2.71,3.36,4.7,6.32,4.7c2.97,0,5.5-1.99,6.32-4.7c5.96,2.48,10.15,8.37,10.15,15.24c0,0.71-0.05,1.42-0.14,2.1
	c-0.38-0.07-0.77-0.12-1.17-0.12c-3.62,0-6.59,2.97-6.59,6.59c0,1.66,0.63,3.18,1.65,4.35c-2.81,2.23-6.35,3.56-10.21,3.56
	c-3.87,0-7.41-1.33-10.21-3.56c1.02-1.16,1.65-2.69,1.65-4.35c0-3.62-2.97-6.59-6.59-6.59c-0.4,0-0.79,0.05-1.17,0.12
	c-0.09-0.69-0.14-1.39-0.14-2.1C8.52,19.19,12.71,13.3,18.67,10.82L18.67,10.82z M9.84,30.68c2.2,0,3.95,1.75,3.95,3.95
	c0,2.2-1.75,3.95-3.95,3.95c-2.2,0-3.95-1.75-3.95-3.95C5.88,32.43,7.64,30.68,9.84,30.68L9.84,30.68z M40.15,30.68
	c2.2,0,3.95,1.75,3.95,3.95c0,2.2-1.75,3.95-3.95,3.95c-2.2,0-3.95-1.75-3.95-3.95C36.2,32.43,37.95,30.68,40.15,30.68L40.15,30.68z
	"
      />
    </SVG>
  </div>
);

Logo.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default Logo;
